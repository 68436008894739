import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import "./Carousel.scss";
import { Unity, unities } from "../../data/unities";
import Button from "../button/Button";
import classNames from "classnames";
import { LeftArrow, RightArrow } from "../logos/logos";
import { useMediaQuery } from "react-responsive";

const handleRedirect = (url: string) => () => {
  window.open(url);
};

function AppCarousel() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  let renderArrow =
    (arrowName: string, ArrowComponent: any) =>
    (clickHandler: () => void, hasPrev: boolean, label: string) =>
      (
        <div className={arrowName} onClick={clickHandler}>
          {hasPrev && <ArrowComponent />}
        </div>
      );
  let renderArrowPrev = renderArrow("arrowPrev", LeftArrow);
  let renderArrowNext = renderArrow("arrowNext", RightArrow);

  let getImageStyle = (url?: string) => {
    if (!url) return {};
    if (!isTabletOrMobile) {
      return {
        background: `url("${url}") lightgray 0px 0px / cover no-repeat`,
      };
    }

    return {
      background: `url("${url}") lightgray 50% / cover no-repeat`,
    };
  };

  let contentClass = classNames("carousel-content", {
    unique: unities.length === 1,
  });

  const handleButtonClick = (unity: Unity) =>
    handleRedirect(`tel:${unity.contact.replace(/\D/g, '')}`);

  let getImageUrl = (unity: Unity) => {
    if(!unity.imageMobile) {
      return unity.image;
    }
    return isTabletOrMobile ? unity.imageMobile : unity.image;
  }

  return (
    <Carousel
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      swipeable={false}
      renderArrowPrev={renderArrowPrev}
      renderArrowNext={renderArrowNext}
      className="app-carousel"
    >
      {unities.map((unity) => (
        <div className="carousel-item" key={unity.name}>
          <div className={contentClass}>
            <div
              className="carousel-content-img"
              style={getImageStyle(getImageUrl(unity))}
            ></div>
            <div className="carousel-content-body">
              <div className="carousel-content-body-txt">
                <h4>{unity.name}</h4>
                <p className="caption">
                  <b>Endereço:</b>
                </p>
                <p className="caption">{unity.address}</p>
                <p className="caption">
                  <b>Horário:</b>
                </p>
                <p className="caption">{unity.openingHours}</p>
                <p className="caption">
                  <b> &#62; Ligue e agende sua consulta conosco:</b>
                </p>
                <p className="caption">{unity.contact}</p>
              </div>
              <div className="carousel-content-body-cta">
                <Button
                  onClick={handleButtonClick(unity)}
                  text="AGENDAR AGORA"
                ></Button>
                <a href={unity.mapView} target="_blank" rel="noreferrer">
                  Veja no mapa
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
}

export default AppCarousel;
