import React from "react";
import "./Menu.scss";
import Button from "../button/Button";
import { WhatsappLogo } from "../logos/logos";
import { Link } from "react-router-dom";

function Menu() {
  const handleRedirect = (url: string) => () => {
    window.open(url);
  };
  const handleWhatsappClick = handleRedirect("https://wa.me/5511943160108");

  return (
    <div className="menu">
      <p className="body primary">
        <Link to="#sobre">Sobre Nós</Link>
      </p>
      <p className="body primary">
        <Link to="#agendamento">Especialidades</Link>
      </p>
      <p className="body primary">
        <Link to="#unidades">Unidades</Link>
      </p>
      <Button
        color="dark-blue"
        text="DÚVIDAS"
        onClick={handleWhatsappClick}
        logo={<WhatsappLogo />}
      ></Button>
    </div>
  );
}

export default Menu;
