import React from "react";
import "./Header.scss";
import logo from "../../../../assets/images/logo-horizontal-blue.svg";
import Menu from "../Menu/Menu";

function Header() {
  return (
    <div className="header">
      <div className="logo-wrapper">
        <img src={logo} alt="Logo" />
      </div>

      <Menu></Menu>
    </div>
  );
}

export default Header;
