import santoAmaroImage from '../images/EspacoSaude-StoAmaro_desk.jpg'
import santoAmaroMobileImage from '../images/EspacoSaude-StoAmaro_mob.jpg.jpg'

export interface Unity {
  name: string;
  address: string;
  openingHours: string;
  contact: string;
  sContact: string;
  mapView: string;
  image?: any;
  imageMobile?: any;
}

export const unities: Unity[] = [
  {
    name: "Espaço Saúde Santo Amaro",
    address: "Rua Amador Bueno, 308 - Santo Amaro",
    openingHours: "Seg. à Sábado: Das 07h às 20h",
    contact: "(11) 3004-1000",
    sContact: "1130041000",
    image: santoAmaroImage,
    imageMobile: santoAmaroMobileImage,
    mapView: "https://www.google.com/maps/place/R.+Amador+Bueno,+308+-+Santo+Amaro,+S%C3%A3o+Paulo+-+SP,+04752-005/@-23.6523295,-46.7109661,21z/data=!4m6!3m5!1s0x94ce51aaeda272bd:0xf4ab190b127c12d3!8m2!3d-23.6522745!4d-46.7109057!16s%2Fg%2F11b8v83js8?entry=ttu"
  },
  // {
  //   name: "Espaço Saúde Vila Mariana",
  //   address: "Av. Conselheiro Rodrigues Alves, 180 - Vila Mariana, São Paulo - SP, 04014-000",
  //   openingHours: "Seg. à Sexta: Das 07h às 19h",
  //   contact: "(11) 3003-1030",
  //   sContact: "1130031030",
  //   mapView: "https://www.google.com/maps/place/Amil+Espa%C3%A7o+Sa%C3%BAde/data=!4m7!3m6!1s0x94ce592917438b57:0x432b6f0e1efd2a87!8m2!3d-23.5823437!4d-46.6403888!16s%2Fg%2F11n06t80g1!19sChIJV4tDFylZzpQRhyr9Hg5vK0M?authuser=0&hl=pt-BR&rclk=1"
  // },

]