import React from "react";
import "./Cards.scss";

interface CardItemOptions {
  title: string;
  body: string;
  icon?: any;
}

function CardItem({ title, body, icon }: CardItemOptions) {
  return (
    <div className="card-item">
      <div className="card-item-icon">{icon}</div>
      <div className="card-item-text">
        <h4>{title}</h4>
        <div className="body">{body}</div>
      </div>
    </div>
  );
}

interface CardParams {
  items?: CardItemOptions[]
}

function Cards({items = []} :CardParams) {
  
  return (
    <div className="card">
      {items.map((item, index) => (
        <CardItem key= {`card-item-${index}`} title={item.title} body={item.body} icon={item.icon} />
      ))}
    </div>
  );
}

export default Cards;
