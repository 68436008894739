import "react-modern-drawer/dist/index.css";
import "./Layout.scss";

import React, { PropsWithChildren } from "react";

import Drawer from "react-modern-drawer";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import HeaderMobile from "../HeaderMobile/HeaderMobile";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import { WhatsappLogo } from "../logos/logos";

function Layout({ children }: PropsWithChildren): React.JSX.Element {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleRedirect = (url: string) => () => {
    window.open(url);
  };
  const handleWhatsappClick = handleRedirect("https://wa.me/5511943160108");

  return (
    <div className="layout">
      {isTabletOrMobile ? (
        <HeaderMobile onMenuClick={toggleDrawer} isOpen={isOpen}></HeaderMobile>
      ) : (
        <Header></Header>
      )}
      {isTabletOrMobile && (
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          lockBackgroundScroll
          className="layout-drawer"
          customIdSuffix="layout"
        >
          <p className="body primary" onClick={toggleDrawer}>
            <Link to="#sobre">Sobre Nós</Link>
          </p>
          <p className="body primary" onClick={toggleDrawer}>
            <Link to="#agendamento">Especialidades</Link>
          </p>
          <p className="body primary" onClick={toggleDrawer}>
            <Link to="#unidades">Unidades</Link>
          </p>
          <Button
            color="dark-blue"
            text="DÚVIDAS"
            onClick={handleWhatsappClick}
            logo={<WhatsappLogo />}
          ></Button>
        </Drawer>
      )}
      <div className="layout-body">{children}</div>
      <Footer></Footer>
    </div>
  );
}

export default Layout;
