import React from "react";
import logo from "../../../../assets/images/logo-blue.svg";
import "./HeaderMobile.scss";
import MenuMobile from "../MenuMobile/MenuMobile";

interface HeaderMobileProps {
  onMenuClick?: () => void;
  isOpen?: boolean;
}

function HeaderMobile(props: HeaderMobileProps) {
  return (
    <div className="header-mobile">
      <div className="logo-wrapper">
        <img src={logo} alt="Logo" />
      </div>
      <MenuMobile onMenuClick={props.onMenuClick} isOpen={props.isOpen}></MenuMobile>
    </div>
  );
}

export default HeaderMobile;
