import { MenuCloseLogo, MenuLogo } from "../logos/logos";
import "./MenuMobile.scss";
import React from "react";

interface MenuMobileProps {
  onMenuClick?: () => void;
  isOpen?: boolean;
}

function MenuMobile(props: MenuMobileProps) {
  return (
    <>
      <div className="menu-mobile" onClick={props.onMenuClick}>
        {props.isOpen ? <MenuCloseLogo></MenuCloseLogo> : <MenuLogo></MenuLogo>}
      </div>
    </>
  );
}

export default MenuMobile;
