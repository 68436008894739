import classNames from "classnames";
import React from "react";
import "./Button.scss";

export type ButtonType = "fullfil" | "outline";
export type ButtonColor =
  | "blue"
  | "light-blue"
  | "dark-blue"
  | "white"
  | "grey";

export enum ButtonColors {
  BLUE = "blue",
  LIGHTBLUE = "light-blue",
  DARKBLUE = "dark-blue",
  WHITE = "white",
  GREY = "grey",
}

export interface ButtonProps {
  logo?: React.JSX.Element;
  buttonType?: ButtonType;
  text?: string;
  onClick?: (...args: any[]) => any;
  color?: ButtonColor;
}

function Button({ onClick, buttonType, text, color, logo }: ButtonProps) {
  const bt: ButtonType = buttonType || "fullfil";
  const buttonColor: ButtonColor = color || ButtonColors.LIGHTBLUE;

  const buttonClasses = classNames("button", bt, buttonColor);
  return (
    <button className={buttonClasses} onClick={onClick}>
      {logo ? (<div className="button-icon">{logo}</div> ): null}
      <div className="button-text">{text}</div>
    </button>
  );
}

export default Button;
