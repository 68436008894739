import React from "react";

export function WhatsappLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M0.0612793 23.9912L1.78431 17.5714C0.813467 15.8134 0.297431 13.8455 0.297431 11.8513C0.297431 5.31777 5.61521 0 12.1487 0C18.6823 0 24.0001 5.31777 24.0001 11.8513C24.0001 18.3848 18.6823 23.7026 12.1487 23.7026C10.1896 23.7026 8.24787 23.2041 6.51609 22.2682L0.0700242 24L0.0612793 23.9912ZM6.83096 19.8542L7.23329 20.0991C8.72017 20.9912 10.417 21.4548 12.14 21.4548C17.4403 21.4548 21.7522 17.1428 21.7522 11.8425C21.7522 6.54225 17.4403 2.23031 12.14 2.23031C6.8397 2.23031 2.52775 6.54225 2.52775 11.8425C2.52775 13.5918 3.01755 15.3149 3.93591 16.828L4.18081 17.2391L3.21871 20.8251L6.82221 19.863L6.83096 19.8542Z" />
      <path d="M16.5482 13.4953C16.0584 13.2067 15.4286 12.8743 14.8514 13.1105C14.4141 13.2942 14.1254 13.9851 13.8456 14.335C13.6969 14.5186 13.5219 14.5449 13.3033 14.4574C11.6502 13.8015 10.382 12.6907 9.47239 11.1688C9.31495 10.9326 9.34119 10.749 9.53361 10.5303C9.81349 10.2067 10.1546 9.83935 10.2333 9.40203C10.312 8.96472 10.1021 8.44868 9.91844 8.06384C9.69104 7.5653 9.42865 6.85684 8.93011 6.57695C8.4753 6.31456 7.8718 6.46325 7.46072 6.79561C6.75227 7.37287 6.41116 8.27376 6.42865 9.15714C6.42865 9.41078 6.46364 9.66441 6.52486 9.90931C6.6648 10.4953 6.93594 11.0463 7.24206 11.5711C7.46947 11.9647 7.72311 12.3495 7.99425 12.7169C8.87763 13.9151 9.97967 14.9647 11.2566 15.7431C11.8951 16.1367 12.5773 16.4778 13.2858 16.714C14.0817 16.9764 14.7902 17.2475 15.6561 17.0901C16.5569 16.9151 17.4491 16.3641 17.7989 15.4895C17.9039 15.2358 17.9563 14.9472 17.8951 14.6761C17.7727 14.1163 17.0117 13.7839 16.5569 13.5128L16.5482 13.4953Z" />
    </svg>
  );
}

export function EmailLogo() {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.3304 0.5625H4.66958C2.36912 0.5625 0.5 2.43162 0.5 4.73208V15.0602C0.5 18.451 3.25576 21.2187 6.65852 21.2187H20.3415C23.7323 21.2187 26.5 18.463 26.5 15.0602V4.73208C26.5 2.43162 24.6309 0.5625 22.3304 0.5625ZM21.6355 3.31826L14.8539 11.0943C14.5064 11.4897 14.0272 11.7054 13.5 11.7054C12.9728 11.7054 12.4935 11.4897 12.1461 11.0943L5.35253 3.31826H21.6355ZM20.3415 18.451H6.65852C4.7894 18.451 3.25576 16.9293 3.25576 15.0482V5.10351L10.0613 12.9035C10.924 13.898 12.182 14.4611 13.488 14.4611C14.794 14.4611 16.0521 13.898 16.9147 12.9035L23.7203 5.10351V15.0482C23.7203 16.9173 22.1986 18.451 20.3175 18.451H20.3415Z" />
    </svg>
  );
}

export function MenuLogo() {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5 19.5C14.5 18.8096 15.0596 18.25 15.75 18.25H38.25C38.9404 18.25 39.5 18.8096 39.5 19.5C39.5 20.1904 38.9404 20.75 38.25 20.75H15.75C15.0596 20.75 14.5 20.1904 14.5 19.5Z" />
      <path d="M14.5 27C14.5 26.3096 15.0596 25.75 15.75 25.75H38.25C38.9404 25.75 39.5 26.3096 39.5 27C39.5 27.6904 38.9404 28.25 38.25 28.25H15.75C15.0596 28.25 14.5 27.6904 14.5 27Z" />
      <path d="M15.75 33.25C15.0596 33.25 14.5 33.8096 14.5 34.5C14.5 35.1904 15.0596 35.75 15.75 35.75H38.25C38.9404 35.75 39.5 35.1904 39.5 34.5C39.5 33.8096 38.9404 33.25 38.25 33.25H15.75Z" />
    </svg>
  );
}

export function MenuCloseLogo() {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.1612 35.839C17.673 35.3508 17.673 34.5593 18.1612 34.0712L34.0711 18.1613C34.5592 17.6731 35.3507 17.6731 35.8388 18.1613C36.327 18.6494 36.327 19.4409 35.8388 19.929L19.9289 35.839C19.4408 36.3271 18.6493 36.3271 18.1612 35.839Z" />
      <path d="M18.1612 18.1613C18.6493 17.6731 19.4408 17.6731 19.9289 18.1613L35.8388 34.0712C36.327 34.5593 36.327 35.3508 35.8388 35.839C35.3507 36.3271 34.5592 36.3271 34.0711 35.839L18.1612 19.929C17.673 19.4409 17.673 18.6494 18.1612 18.1613Z" />
    </svg>
  );
}

export function LeftArrow() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.5" r="12" fill="#48A6DC" />
      <path
        d="M5.29289 11.7929C4.90237 12.1834 4.90237 12.8166 5.29289 13.2071L11.6569 19.5711C12.0474 19.9616 12.6805 19.9616 13.0711 19.5711C13.4616 19.1805 13.4616 18.5474 13.0711 18.1569L7.41421 12.5L13.0711 6.84315C13.4616 6.45262 13.4616 5.81946 13.0711 5.42893C12.6805 5.03841 12.0474 5.03841 11.6569 5.42893L5.29289 11.7929ZM18 13.5C18.5523 13.5 19 13.0523 19 12.5C19 11.9477 18.5523 11.5 18 11.5V13.5ZM6 13.5H18V11.5H6V13.5Z"
        fill="white"
      />
    </svg>
  );
}

export function RightArrow() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.5" r="12" fill="#48A6DC" />
      <path
        d="M6 11.5C5.44772 11.5 5 11.9477 5 12.5C5 13.0523 5.44772 13.5 6 13.5V11.5ZM18.7071 13.2071C19.0976 12.8166 19.0976 12.1834 18.7071 11.7929L12.3431 5.42893C11.9526 5.03841 11.3195 5.03841 10.9289 5.42893C10.5384 5.81946 10.5384 6.45262 10.9289 6.84315L16.5858 12.5L10.9289 18.1569C10.5384 18.5474 10.5384 19.1805 10.9289 19.5711C11.3195 19.9616 11.9526 19.9616 12.3431 19.5711L18.7071 13.2071ZM6 13.5H18V11.5H6V13.5Z"
        fill="white"
      />
    </svg>
  );
}
