import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import logo from "../../../../assets/images/logo-horizontal-blue.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="footer-stack">
        <div className="footer-links">
          <Link to="#sobre">Sobre nós</Link>
          <Link to="#agendamento">Especialidades</Link>
          <Link to="#unidades">Unidades</Link>
          <Link
            to="https://institucional.amil.com.br/comunicado-de-privacidade-da-amil"
            target="_blank"
            rel="noreferrer"
          >
            Comunicado de Privacidade da Amil
          </Link>
          <Link
            to="https://wa.me/5511943160108"
            target="_blank"
            rel="noreferrer"
          >
            Dúvidas
          </Link>
        </div>
        <div className="footer-copyright">
          <p>Copyright © 2024 Espaço Saúde | Todos os direitos reservados</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
