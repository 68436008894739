import incluaLogo from "./images/inclua-logo.png";
import amilDentalLogo from "./images/amil-logo.png";
import amilESLogo from "./images/es-logo.png";
// import aliancaLogo from "./images/alianca-logo.png";
// import ciaLogo from "./images/cia-logo.png";
import linkedinLogo from "./images/linkedin-logo.png";
import "./root.scss";
import halfCircle from "./images/half-circle.png";

function Root() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-wrapper">
          <img src={incluaLogo} className="App-logo" alt="logo" />
        </div>
      </header>
      <div className="App-body">
        <div className="h1">
          <h1>Site em construção</h1>
        </div>
        <div className="h2">
          <h2>Clique para saber mais sobre nossas unidades:</h2>
        </div>
        <div className="img-container">
          <a
            href="https://www.amilcuidadocerto.com.br/clinicas-amil-dental/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="img-wrapper">
              <img className="brand amil" src={amilDentalLogo} alt="" />
            </div>
          </a>
          <a href="/espaco-saude" target="_blank" rel="noreferrer">
            <div className="img-wrapper">
              <img className="brand es" src={amilESLogo} alt="" />
            </div>
          </a>
          {/* <a
            href="https://www.clinicaallianca.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="img-wrapper">
              <img className="brand alianca" src={aliancaLogo} alt="" />
            </div>
          </a>
          <a
            href="https://ciadaconsulta.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="img-wrapper">
              <img className="brand cia" src={ciaLogo} alt="" />
            </div>
          </a> */}
        </div>
        <div className="h2">
          <h2>Ou acesse o nosso LinkedIn:</h2>
        </div>
        <div className="img-container">
          <a
            href="https://www.linkedin.com/company/ciadaconsulta/mycompany/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="img-wrapper">
              <img className="social" src={linkedinLogo} alt="" />
            </div>
          </a>
        </div>
      </div>
      <footer className="App-footer">
        <img src={halfCircle} alt="" />
      </footer>
    </div>
  );
}

export default Root;
